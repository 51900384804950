
import { defineComponent } from 'vue'
import PlanPaymentFormBlock from '@/components/pages/plans/paymentForm/PlanPaymentFormBlock.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmStatus,
    TmButton,
    PlanPaymentFormBlock,
  },
})
