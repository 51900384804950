import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a81883f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payment__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_credit_card = _resolveComponent("credit-card")!
  const _component_bank_transfer = _resolveComponent("bank-transfer")!
  const _component_tm_radio_tabs = _resolveComponent("tm-radio-tabs")!

  return (_openBlock(), _createBlock(_component_tm_radio_tabs, {
    modelValue: _ctx.selectedMethod,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMethod) = $event)),
    tabs: _ctx.tabs
  }, {
    title: _withCtx(({ title }) => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(title), 1)
    ]),
    "panel-credit": _withCtx(() => [
      _createVNode(_component_credit_card)
    ]),
    "panel-bank": _withCtx(() => [
      _createVNode(_component_bank_transfer)
    ]),
    _: 1
  }, 8, ["modelValue", "tabs"]))
}