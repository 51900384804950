import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a95c12b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "plan-billing-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_form_line, {
      label: "First name",
      class: "plan-billing-form__line"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.firstName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
          placeholder: "Enter first name",
          validators: "required",
          "error-hint": "This is required field"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_tm_form_line, {
      label: "Address (Line 1)",
      class: "plan-billing-form__line"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.addressFirstLine,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addressFirstLine) = $event)),
          placeholder: "Enter address",
          validators: "required",
          "error-hint": "This is required field"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_tm_form_line, {
      label: "Last name",
      class: "plan-billing-form__line"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.lastName,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lastName) = $event)),
          placeholder: "Enter last name",
          validators: "required",
          "error-hint": "This is required field"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_tm_form_line, {
      label: "Address (Line 2)",
      class: "plan-billing-form__line"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.addressSecondLine,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addressSecondLine) = $event)),
          placeholder: "Enter address"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_tm_form_line, {
      label: "Accounting email",
      class: "plan-billing-form__line"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.email) = $event)),
          placeholder: "Enter accounting email"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_tm_form_line, {
      label: "City / Town",
      class: "plan-billing-form__line"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.city,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.city) = $event)),
          placeholder: "Enter city"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_tm_form_line, {
      label: "Company",
      class: "plan-billing-form__line"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.company,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.company) = $event)),
          placeholder: "Enter company"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_tm_form_line, {
      label: "Country / Region / State",
      class: "plan-billing-form__line"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.city,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.city) = $event)),
          validators: "required",
          "error-hint": "This is required field",
          placeholder: "Enter country / region / state"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_tm_form_line, {
      label: "Country",
      class: "plan-billing-form__line"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.country,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.country) = $event)),
          type: "selectCountry",
          clearable: false,
          disable: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_tm_form_line, {
      label: "Postal code / Zip code",
      class: "plan-billing-form__line"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.zip,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.zip) = $event)),
          validators: "required",
          "error-hint": "This is required field",
          placeholder: "Enter postal code"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}