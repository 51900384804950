
import { defineComponent, ref } from 'vue'
import TmRadioTabs from '@/components/shared/TmRadioTabs.vue'
import type { RadioTab } from '@/definitions/shared/types'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmStatus,
    TmRadioTabs,
    TmFormLine,
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    const tabs = ref<RadioTab[]>([
      {
        name: 'valid',
        label: 'I am a business with a valid VAT number',
        text: 'VAT zer-rated: Article 56.1 of Council Directive 2006/112/EC applies. Subject to reverse charge in the country of receipt. Please enter your EU VAT number below.',
      },
      {
        name: 'not-valid',
        label: 'I am do not have a valid VAT number',
        text: 'I understand I may be VAT-assessed on renewals and future purchases from Textmagic.',
      },
    ])

    const selectedTab = ref<'valid' | 'not-valid'>('valid')
    const vat = ref('1098384376')

    return {
      tabs,
      selectedTab,
      vat,
      isSmMax,
    }
  },
})
