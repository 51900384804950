
import { defineComponent, ref } from 'vue'
import PlanPaymentFormBlock from '@/components/pages/plans/paymentForm/PlanPaymentFormBlock.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmColoredUl,
    PlanPaymentFormBlock,
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    const autoRecharge = ref(true)

    return {
      autoRecharge,
      isSmMax,
    }
  },
})
