
import { defineComponent } from 'vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import PlanAutoRecharge from '@/components/pages/plans/paymentForm/PlanAutoRecharge.vue'
import PlanChooseMethod from '@/components/pages/plans/paymentForm/PlanChooseMethod.vue'
import PlanBilling from '@/components/pages/plans/paymentForm/PlanBilling.vue'
import PlanVat from '@/components/pages/plans/paymentForm/PlanVat.vue'
import PlanPaymentFormFooter from '@/components/pages/plans/paymentForm/PlanPaymentFormFooter.vue'
import PlanChooseCredit from '@/components/pages/plans/PlanChooseCredit.vue'

export default defineComponent({
  components: {
    PlanChooseCredit,
    WhiteBlock,
    PlanAutoRecharge,
    PlanChooseMethod,
    PlanBilling,
    PlanVat,
    PlanPaymentFormFooter,
  },
})
