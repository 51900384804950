import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_payment_form = _resolveComponent("plan-payment-form")!
  const _component_single_page_content = _resolveComponent("single-page-content")!

  return (_openBlock(), _createBlock(_component_single_page_content, {
    title: "Buy Textmagic credit",
    text: "We will refund all unused credit on request. No questions asked.",
    class: "single-page-content"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_plan_payment_form)
    ]),
    _: 1
  }))
}