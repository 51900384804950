
import { defineComponent, ref } from 'vue'
import PlanPaymentFormBlock from '@/components/pages/plans/paymentForm/PlanPaymentFormBlock.vue'

export default defineComponent({
  components: {
    PlanPaymentFormBlock,
  },
  setup() {
    const creditList = ref<string[]>([
      '$20', '$40', '$60', '$80', '$100',
    ])
    const credit = ref(creditList.value[0])

    return {
      creditList,
      credit,
    }
  },
})
