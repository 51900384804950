
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { RadioTab } from '@/definitions/shared/types'

export default defineComponent({
  props: {
    noBorders: {
      type: Boolean,
    },
    modelValue: {
      type: String,
    },
    tabs: {
      type: Array as PropType<RadioTab[]>,
    },
    smTextWidthPadding: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { slots }) {
    const hasPanelSlot = (name: string) => !!slots[`panel-${name}`]
    const hasLogoSlot = (name: string) => !!slots[`title-logo-${name}`]

    return {
      hasPanelSlot,
      hasLogoSlot,
    }
  },
})
