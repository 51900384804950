
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

type CardType = {
  cardNumber: string;
  expiration: string;
  name: string;
  cvv: string;
}

export default defineComponent({
  components: {
    TmFormLine,
  },
  setup() {
    const card = ref<CardType>({
      cardNumber: '',
      expiration: '',
      name: '',
      cvv: '',
    })

    return {
      card,
    }
  },
})
