import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55431ed7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "plan-payment-form-block" }
const _hoisted_2 = {
  key: 0,
  class: "headline-18 d-flex justify-between align-center pb-2 lh-22 emphasize--text"
}
const _hoisted_3 = { class: "ls-reduce-2" }
const _hoisted_4 = {
  key: 1,
  class: "body-text-regular-14 mb-4 lh-20 emphasize--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "right-btn", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}