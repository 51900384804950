
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import type { FlagCountry } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmFormLine,
  },
  setup() {
    const firstName = ref('')
    const lastName = ref('')
    const addressFirstLine = ref('')
    const addressSecondLine = ref('')
    const email = ref('')
    const city = ref('')
    const company = ref('')
    const zip = ref('')
    const country = ref<FlagCountry>(phoneCountryVariant[0])

    return {
      firstName,
      lastName,
      addressFirstLine,
      addressSecondLine,
      email,
      city,
      company,
      zip,
      country,
    }
  },
})
