
import { defineComponent } from 'vue'
import PlanPaymentForm from '@/components/pages/plans/PlanPaymentForm.vue'
import SinglePageContent from '@/components/layout/SinglePageContent.vue'

export default defineComponent({
  components: {
    PlanPaymentForm,
    SinglePageContent,
  },
})
