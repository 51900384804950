
import { defineComponent, ref } from 'vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import type { DetailsTableItemType } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    DetailsTable,
  },
  setup() {
    const billingDetails = ref<DetailsTableItemType[]>([
      {
        label: 'Full name',
        value: 'John Doe',
      },
      {
        label: 'Company',
        value: 'Textmagic Ltd.',
      },
      {
        label: 'Billing address',
        value: 'Evergreen 24\nLos Angeles\nCalifornia\nCB1 2LA\nUnited States',
      },
      {
        label: 'Email',
        value: 'jamesbrown@gmail.com',
      },
    ])

    return {
      billingDetails,
    }
  },
})
