import type { RadioTab } from '@/definitions/shared/types'

export const bankTab: RadioTab = {
  name: 'bank',
  label: 'Bank transfer',
  logos: [
    require('@/assets/images/payment/bank-transfer.svg'),
  ],
}

export const creditCard: RadioTab = {
  name: 'credit',
  label: 'Credit card',
  logos: [
    require('@/assets/images/payment/visa.svg'),
    require('@/assets/images/payment/mastercard.png'),
    require('@/assets/images/payment/american-express.svg'),
  ],
}

export const paypal: RadioTab = {
  name: 'paypal',
  label: 'PayPal',
  logos: [
    require('@/assets/images/payment/paypal.svg'),
  ],
}

export const mobilePaypal: RadioTab = {
  ...paypal,
  text: 'You can use your PayPal account as a Textmagic payment method.',
}

export const mobileCreditCard: RadioTab = {
  ...creditCard,
  text: 'We support VISA, MasterCard or American Express.',
}
