import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6194fdc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "tm-radio-tabs__tab__header-radio" }
const _hoisted_3 = { class: "subhead-semi-bold-16 neutral--text lh-20" }
const _hoisted_4 = {
  key: 0,
  class: "tm-radio-tabs__tab__header-logo"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-radio-tabs", {
      'tm-radio-tabs--no-borders': _ctx.noBorders,
    }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: _normalizeClass(["tm-radio-tabs__tab", {
        'tm-radio-tabs__tab--selected': _ctx.modelValue === item.name,
        'tm-radio-tabs__tab--with-text': item.text,
      }])
      }, [
        _createElementVNode("div", {
          class: "tm-radio-tabs__tab__header",
          onClick: _withModifiers(($event: any) => (_ctx.$emit('update:modelValue', item.name)), ["stop"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_tm_field, {
              "model-value": _ctx.modelValue,
              type: "radio",
              val: item.name,
              class: "mt-half",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
            }, null, 8, ["model-value", "val"]),
            _renderSlot(_ctx.$slots, "title", {
              title: item.label ? item.label : item.name
            }, () => [
              _createElementVNode("div", _hoisted_3, _toDisplayString(item.label ? item.label : item.name), 1)
            ], true)
          ]),
          (_ctx.hasLogoSlot(item.name) || item.logos)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, `title-logo-${item.name}`, {}, () => [
                  (item.logos)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.logos, (logo, i) => {
                        return (_openBlock(), _createElementBlock("img", {
                          key: i,
                          src: logo
                        }, null, 8, _hoisted_5))
                      }), 128))
                    : _createCommentVNode("", true)
                ], true)
              ]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_1),
        (item.text)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["tm-radio-tabs__tab__text", {
          'sm-padding': _ctx.smTextWidthPadding
        }])
            }, _toDisplayString(item.text), 3))
          : _createCommentVNode("", true),
        (_ctx.hasPanelSlot(item.name))
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["tm-radio-tabs__tab__body", {
          'sm-padding': _ctx.smTextWidthPadding
        }])
            }, [
              _renderSlot(_ctx.$slots, `panel-${item.name}`, {}, undefined, true)
            ], 2))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ], 2))
}