import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_choose_credit = _resolveComponent("plan-choose-credit")!
  const _component_plan_choose_method = _resolveComponent("plan-choose-method")!
  const _component_plan_billing = _resolveComponent("plan-billing")!
  const _component_plan_auto_recharge = _resolveComponent("plan-auto-recharge")!
  const _component_plan_vat = _resolveComponent("plan-vat")!
  const _component_plan_payment_form_footer = _resolveComponent("plan-payment-form-footer")!
  const _component_white_block = _resolveComponent("white-block")!

  return (_openBlock(), _createBlock(_component_white_block, { class: "plan-payment-form" }, {
    default: _withCtx(() => [
      _createVNode(_component_plan_choose_credit),
      _createVNode(_component_plan_choose_method),
      _createVNode(_component_plan_billing),
      _createVNode(_component_plan_auto_recharge),
      _createVNode(_component_plan_vat),
      _createVNode(_component_plan_payment_form_footer)
    ]),
    _: 1
  }))
}