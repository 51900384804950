
import { defineComponent } from 'vue'
import PlanPaymentFormBlock from '@/components/pages/plans/paymentForm/PlanPaymentFormBlock.vue'
import PaymentMethods from '@/components/pages/shared/payment/PaymentMethods.vue'
import TmAlert from '@/components/shared/TmAlert.vue'

export default defineComponent({
  components: {
    PaymentMethods,
    PlanPaymentFormBlock,
    TmAlert,
  },
})
