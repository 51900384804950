
import { defineComponent, ref } from 'vue'
import type { RadioTab } from '@/definitions/shared/types'
import TmRadioTabs from '@/components/shared/TmRadioTabs.vue'
import CreditCard from '@/components/pages/shared/payment/CreditCard.vue'
import BankTransfer from '@/components/pages/shared/payment/BankTransfer.vue'
import { creditCard, paypal, bankTab } from '@/definitions/shared/payment/data'

export default defineComponent({
  components: {
    TmRadioTabs,
    BankTransfer,
    CreditCard,
  },
  props: {
    hideBank: {
      type: Boolean,
    },
  },
  setup(props) {
    const tabs = ref<RadioTab[]>(props.hideBank ? [creditCard, paypal] : [creditCard, paypal, bankTab])

    const selectedMethod = ref<'credit' | 'paypal' | 'bank'>('credit')

    return {
      tabs,
      selectedMethod,
    }
  },
})
