import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_vat_information = _resolveComponent("plan-vat-information")!
  const _component_plan_vat_form = _resolveComponent("plan-vat-form")!
  const _component_plan_payment_form_block = _resolveComponent("plan-payment-form-block")!

  return (_openBlock(), _createBlock(_component_plan_payment_form_block, { title: "EU VAT information" }, {
    default: _withCtx(() => [
      (!_ctx.isEditable)
        ? (_openBlock(), _createBlock(_component_plan_vat_information, {
            key: 0,
            class: "mt-2",
            onOnEdit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isEditable = true))
          }))
        : (_openBlock(), _createBlock(_component_plan_vat_form, { key: 1 }))
    ]),
    _: 1
  }))
}