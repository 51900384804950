
import { defineComponent, ref } from 'vue'
import PlanPaymentFormBlock from '@/components/pages/plans/paymentForm/PlanPaymentFormBlock.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import PlanBillingDetails from '@/components/pages/plans/paymentForm/billing/PlanBillingDetails.vue'
import PlanBillingForm from '@/components/pages/plans/paymentForm/billing/PlanBillingForm.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    PlanBillingForm,
    PlanBillingDetails,
    TmFormLine,
    TmButton,
    PlanPaymentFormBlock,
  },
  setup() {
    const isEditable = ref(false)
    const po = ref('')
    return {
      po,
      isEditable,
    }
  },
})
