
import { defineComponent, ref } from 'vue'
import PlanPaymentFormBlock from '@/components/pages/plans/paymentForm/PlanPaymentFormBlock.vue'
import PlanVatInformation from '@/components/pages/plans/paymentForm/vat/PlanVatInformation.vue'
import PlanVatForm from '@/components/pages/plans/paymentForm/vat/PlanVatForm.vue'

export default defineComponent({
  components: {
    PlanVatInformation,
    PlanVatForm,
    PlanPaymentFormBlock,
  },
  setup() {
    const isEditable = ref(false)

    return {
      isEditable,
    }
  },
})
